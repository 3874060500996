import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatpopupComponent } from 'src/app/components/chatpopup/chatpopup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public selectedSite = '';
  public sitesList: any[] = [];
  constructor(private userService: UserManagementService,private modalserivce: NgbModal) { }

  ngOnInit(): void {
    this.onSiteAdded();
  }
  ngAfterViewInit() {
    this.getSiteList();
  }

  getSiteList() {
    const siteList = this.userService.getSiteDetail();
    if (siteList && siteList.length) {
      this.sitesList = siteList;
      const userDetail = this.userService.getUserDetail();
      if (userDetail && userDetail.role == 1 && this.sitesList && this.sitesList.length) {
        setTimeout(() => {
          this.selectedSite = this.sitesList[0].site_id;
          this.onSiteChange(this.selectedSite);
        })
      }
    }
  }

  onSiteChange(event) {
    this.userService.updateMessage({ 'purpose': 'sitechanged', 'value': event });
    this.userService.selectedSite = this.selectedSite;
  }

  onSiteAdded() {
    this.userService.getMessage().subscribe(data => {
      if (data.purpose === 'siteadded') {
        this.sitesList = data.value;
        if (this.sitesList && this.sitesList.length) {
          const allSiteIds = this.sitesList.map(ele => ele.site_id);
          if (!allSiteIds.includes(this.selectedSite)) {
            this.selectedSite = '';
            setTimeout(() => {
              this.selectedSite = allSiteIds[0];
              this.userService.selectedSite = String(this.selectedSite);
            });
          } else {
            this.selectedSite = '';
            setTimeout(() => this.selectedSite = String(this.userService.selectedSite));
          }
        }
      }
    });
  }
  onChange() {
    this.selectedSite = null;
    this.selectedSite = this.userService.selectedSite;
  }
  aiPopShow(data) {
    const modalRef = this.modalserivce.open(ChatpopupComponent,{ size : 'l', backdrop: 'static' });
    modalRef.componentInstance.src = data;
    modalRef.componentInstance.event = event;
  }
  logout() {
    this.userService.logout();
  }

}
