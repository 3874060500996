export type StartOfEndOFUnit =
  | "year"
  | "month"
  | "day"
  | "hour"
  | "minute"
  | "second";

export type AddSubUnit = StartOfEndOFUnit | "week" | "millisecond";

export class InternalMoment {
  private date: Date;

  constructor(date: Date | string = new Date()) {
    this.date = new Date(date);

    if (!(this.date instanceof Date)) {
      throw new Error("Invalid Date");
    }
  }

  // these function return this/InternalMoment so for date we need to call toDate

  public add(amount: number, unit: AddSubUnit): this {
    switch (unit) {
      case "year":
        this.date.setFullYear(this.date.getFullYear() + amount);
        break;
      case "month": {
        let newMonth = this.date.getMonth() + amount;
        let newYear = this.date.getFullYear();
        if (newMonth > 11) {
          newYear += Math.floor(newMonth / 12);
          newMonth = newMonth % 12;
        }
        this.date.setFullYear(newYear);
        this.date.setMonth(newMonth);
        break;
      }
      case "week":
        this.date.setDate(this.date.getDate() + amount * 7);
        break;
      case "day":
        this.date.setDate(this.date.getDate() + amount);
        break;
      case "hour":
        this.date.setHours(this.date.getHours() + amount);
        break;
      case "minute":
        this.date.setMinutes(this.date.getMinutes() + amount);
        break;
      case "second":
        this.date.setSeconds(this.date.getSeconds() + amount);
        break;
      case "millisecond":
        this.date.setMilliseconds(this.date.getMilliseconds() + amount);
        break;
      default:
        throw new Error("Invalid unit");
    }
    return this;
  }

  public subtract(amount: number, unit: AddSubUnit): this {
    return this.add(-amount, unit);
  }

  public startOf(unit: StartOfEndOFUnit): this {
    switch (unit) {
      case "year":
        this.date.setMonth(0);
        this.date.setDate(1);
        this.date.setHours(0, 0, 0, 0);
        break;
      case "month":
        this.date.setDate(1);
        this.date.setHours(0, 0, 0, 0);
        break;
      case "day":
        this.date.setHours(0, 0, 0, 0);
        break;
      case "hour":
        this.date.setMinutes(0, 0, 0);
        break;
      case "minute":
        this.date.setSeconds(0, 0);
        break;
      case "second":
        this.date.setMilliseconds(0);
        break;
      default:
        throw new Error("Invalid unit");
    }
    return this;
  }

  public endOf(unit: StartOfEndOFUnit): this {
    switch (unit) {
      case "year":
        this.date.setMonth(11);
        this.date.setDate(31);
        this.date.setHours(23, 59, 59, 999);
        break;
      case "month":
        this.date.setMonth(this.date.getMonth() + 1);
        this.date.setDate(0); // Set to last day of the previous month
        this.date.setHours(23, 59, 59, 999);
        break;
      case "day":
        this.date.setHours(23, 59, 59, 999);
        break;
      case "hour":
        this.date.setMinutes(59, 59, 999);
        break;
      case "minute":
        this.date.setSeconds(59, 999);
        break;
      case "second":
        this.date.setMilliseconds(999);
        break;
      default:
        throw new Error("Invalid unit");
    }
    return this;
  }

  public set(unit: StartOfEndOFUnit | "date" | "millisecond", value: number): this {
    switch (unit) {
      case "year":
        this.date.setFullYear(value);
        break;
      case "month":
        this.date.setMonth(value - 1); // Months are 0-based in JavaScript
        break;
      case "date":
      case "day": // 'date' and 'day' are often used interchangeably
        this.date.setDate(value);
        break;
      case "hour":
        this.date.setHours(value);
        break;
      case "minute":
        this.date.setMinutes(value);
        break;
      case "second":
        this.date.setSeconds(value);
        break;
      case "millisecond":
        this.date.setMilliseconds(value);
        break;
      default:
        throw new Error("Invalid unit");
    }
    return this;
  }

  public parse(dateString: string, format: string): InternalMoment {
    const formatParts = format.split(/[-/ :]/);
    const dateParts = dateString.split(/[-/ :]/);

    let year = 0,
      month = 0,
      day = 0,
      hours = 0,
      minutes = 0,
      seconds = 0,
      milliseconds = 0;

    formatParts.forEach((part, index) => {
      const value = parseInt(dateParts[index], 10);
      switch (part) {
        case "YYYY":
        case "yyyy":
          year = value;
          break;
        case "MM":
          month = value - 1; // Months are 0-based in JavaScript
          break;
        case "DD":
        case "dd":
          day = value;
          break;
        case "HH":
        case "hh":
          hours = value;
          break;
        case "mm":
          minutes = value;
          break;
        case "ss":
          seconds = value;
          break;
        case "SSS":
          milliseconds = value;
          break;
      }
    });

    const parsedDate = new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    return new InternalMoment(parsedDate);
  }

  // these function return value or date
  public format(formatString: string): string {
    const pad = (number: number, length: number = 2): string =>
      String(number).padStart(length, "0");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const hour12 = this.date.getHours() % 12 || 12; // Converts to 12-hour format, where 0 becomes 12
    const ampm = this.date.getHours() >= 12 ? "PM" : "AM";

    const replacements: { [key: string]: number | string } = {
      YYYY: this.date.getFullYear(),
      yyyy: this.date.getFullYear(),
      MM: pad(this.date.getMonth() + 1),
      MMM: monthNames[this.date.getMonth()],
      DD: pad(this.date.getDate()),
      dd: pad(this.date.getDate()),
      HH: pad(this.date.getHours()),
      hh: pad(hour12),
      h: String(hour12),
      mm: pad(this.date.getMinutes()),
      ss: pad(this.date.getSeconds()),
      SSS: pad(this.date.getMilliseconds(), 3),
      A: ampm,
      a: ampm.toLowerCase()
    };

    return formatString.replace(
      /YYYY|yyyy|MMM|MM|DD|dd|HH|hh|h|mm|SSS|ss|A|a/g,
      (match) => String(replacements[match])
    );
  }

  public isAfter(otherDate: Date): boolean {
    return this.date > new Date(otherDate);
  }

  isBefore(otherDate: Date): boolean {
    return this.date < new Date(otherDate);
  }

  public diff(otherDate: Date, unit: AddSubUnit): number {
    const date1 = this.date.getTime();
    const date2 = new Date(otherDate).getTime();
    const msDiff = date1 - date2;

    switch (unit) {
      case "year":
        return Math.floor(msDiff / (1000 * 60 * 60 * 24 * 365.25));
      case "month":
        return Math.floor(msDiff / (1000 * 60 * 60 * 24 * 30));
      case "week":
        return Math.floor(msDiff / (1000 * 60 * 60 * 24 * 7));
      case "day":
        return Math.floor(msDiff / (1000 * 60 * 60 * 24));
      case "hour":
        return Math.floor(msDiff / (1000 * 60 * 60));
      case "minute":
        return Math.floor(msDiff / (1000 * 60));
      case "second":
        return Math.floor(msDiff / 1000);
      case "millisecond":
        return msDiff;
      default:
        throw new Error("Invalid unit");
    }
  }

  public toDate(): Date {
    return this.date;
  }
}
